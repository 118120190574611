import React from 'react'

import Layout from '../components/layout/'
import { Language } from '../i18n/types/languages'
import PrivacyContainer from '../containers/privacy'

type Props = {
  pageContext: {
    locale: Language
  }
}

const PrivacyPage = ({ pageContext: { locale } }: Props) => {
  return (
    <Layout locale={locale} clearPage>
      <PrivacyContainer />
    </Layout>
  )
}

export default PrivacyPage
