import React from 'react'
import styled from 'styled-components'
import { injectIntl, intlShape } from 'react-intl'

import { styleVars } from '../theme/theme'

type Props = {
  intl: intlShape
}

const PrivacyContainer = ({ intl }: Props) => {
  return (
    <StyledPrivacyContainer>
      <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'privacy' }) }} />
    </StyledPrivacyContainer>
  )
}

const StyledPrivacyContainer = styled.div`
  a {
    color: ${styleVars.brandPrimary};
  }
`

export default injectIntl(PrivacyContainer)
